<template>
  <Button @click="router.push(localeLocation('/account'))" variant="ghost">
    <div class="flex gap-4 items-center">
      <div class="hidden md:flex flex-col items-end" v-if="!isLoading">
        <span class="font-bold text-mono-black capitalize">{{
          data?.firstName
        }}</span
        ><span class="text-sm text-mono-grey leading-5 font-normal">{{
          $t(data?.planNameFull)
        }}</span>
      </div>
      <CircleAvatar
        :isLoading="isLoading"
        :initials="data?.initials"
      ></CircleAvatar>
    </div>
  </Button>
</template>

<script setup>
const router = useRouter();
const { isLoading, data, suspense } = useCurrentUserSubscriptionQuery();

onServerPrefetch(async () => {
  await suspense();
});
</script>

<style scoped>
.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.circle {
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 160ms;
}

.text {
  color: white;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
</style>
